import React, { useEffect, useState } from 'react';
import { auth } from './firebase.js';

import './styles/App.css';
import Router from './Router';
import { CustomToast } from './components/CustomToast';
import { Button } from './components/Button';
import { UserContext } from './context/UserContext';

const App = () => {
  const [userId, setUserId] = useState();
  const [showToast, setShowToast] = useState(
    localStorage.getItem('acceptedCookies') === null ? true : false
  );

  useEffect(() => {
    auth
      .signInAnonymously()
      .then(() => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            setUserId(user.uid);
          }
        });
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  }, []);

  return (
    <UserContext.Provider value={{ userId: userId }}>
      <CustomToast show={showToast} setShow={setShowToast} hide={false} large={true}>
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-12 col-lg-10 px-0 text-justify">
            Ao continuar a navegar está a consentir a utilização de
            {' '}
            <span role="img" aria-label="cookie emoji">
              {' '}
              🍪
              {' '}
            </span>
            <span className="main-color">cookies</span>
            . Não se preocupe, estas não
            engordam!
            <span role="img" aria-label="shushing face emoji">
              {' '}
              🤫
            </span>
          </div>
          <div className="col-12 col-lg-2 pt-0 pt-sm-3 pt-lg-0 px-0 toast-button">
            <Button
              classes=" mt-3 mt-sm-0"
              text="OK"
              onClick={() => {
                localStorage.setItem('acceptedCookies', true);
                setShowToast(false);
              }}
            />
          </div>
        </div>
      </CustomToast>
      <Router />
    </UserContext.Provider>
  );
};

export default App;
