import React from 'react';
import styled from 'styled-components';

import logo from '../assets/logos/wineLogo.png';

const Styles = styled.div`
  .div {
    position: relative;
    height: 100vh;
    width: 100%;
    margin-top: -82px;
  }

  div img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
  }

`;

const Loading = () => {
  return (
    <Styles>
      <div>
        <img className="animate-flicker" src={logo} alt="logo" />
      </div>
    </Styles>
  );
};

export default Loading;
