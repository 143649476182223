import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BsArrowRight } from 'react-icons/bs';

const Styles = styled.div`
  .btn {
    box-shadow: rgba(25, 28, 31, 0.08) 0px 14px 32px,
      rgba(25, 28, 31, 0.04) 0px 8px 16px, rgba(25, 28, 31, 0.04) 0px -1px 0px;
    background-color: #ae4951;
    color: white;
  }
  
  @media screen and (min-width: 1024px) {
    .btn:hover {
      box-shadow: 0 0 1px rgba(39, 44, 49, 0.1), 0 3px 16px rgba(39, 44, 49, 0.07);
      transition: all 0.3s ease;
      transform: translate3D(0, -1px, 0);
    }

    .btn.disabled:hover {
      box-shadow: rgba(25, 28, 31, 0.08) 0px 14px 32px,
        rgba(25, 28, 31, 0.04) 0px 8px 16px, rgba(25, 28, 31, 0.04) 0px -1px 0px;
      transform: none;
    }
  }
`;

export const Button = (props) => (
  <Styles>
    <button
      className={`btn${props.disabled ? ' disabled' : ''}${props.classes}`}
      onClick={props.onClick !== undefined ? props.onClick : () => {}}
      type={props.type !== undefined ? props.type : ''}
      disabled={props.disabled !== undefined ? props.disabled : ''}
    >
      <span className="d-flex justify-content-center align-items-center">
        {props.text}
        <BsArrowRight className="ml-1" size={22} />
      </span>
    </button>
  </Styles>
);

Button.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
