import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import 'firebase/compat/auth';

// https://firebase.google.com/docs/web/modular-upgrade
// upgrade to firebase modular eventually

const firebaseConfig = {
  apiKey: 'AIzaSyA5m_zjBF8dxWRbPUilnh5RVJYZghidYDk',
  authDomain: 'joanasantosnutri-9f067.firebaseapp.com',
  databaseURL: 'https://joanasantosnutri-9f067.firebaseio.com',
  projectId: 'joanasantosnutri-9f067',
  storageBucket: 'joanasantosnutri-9f067.appspot.com',
  messagingSenderId: '248335709798',
  appId: '1:248335709798:web:ad857e0335941b8d3f9fa7',
  measurementId: 'G-P0D3051XC7',
};

firebase.default.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
export const perf = firebase.performance();
export const auth = firebase.auth();
