import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';
import styled from 'styled-components';

export const CustomToast = ({
  show,
  setShow,
  hide = true,
  large = false,
  children,
}) => {
  const Styles = styled.div`
    .toast-container {
      ${large && 'bottom: 0px;'}
    }

    // .click-through {
    //   pointer-events: auto !important;
    // }

    @media only screen and (min-width: 992px) {
      .toast-button {
        display: flex;
        justify-content: end;
      }
    }

    @media only screen and (min-width: 576px) {
      .toast-container {
        ${large && 'bottom: 10px;'}
      }
    }
  `;

  return (
    <Styles>
      <div className="d-flex justify-content-center align-items-center click-through">
        <div
          aria-live="polite"
          aria-atomic="true"
          className="toast-container"
          style={{
            position: 'fixed',
            // minHeight: '100px',
            minWidth: large ? '0px' : '300px',
            textAlign: 'center',
            top: !large && '87px',
            margin: '10px',
            zIndex: 100000,
            borderRadius: '0.5rem',
          }}
        >
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide={hide}
            style={{
              maxWidth: '1200px',
            }}
          >
            <Toast.Body
              className="bg-neutral"
              style={{
                borderRadius: '0.5rem',
                opacity: '1.0',
                fontSize: '1rem',
              }}
            >
              {children}
            </Toast.Body>
          </Toast>
        </div>
      </div>
    </Styles>
  );
};

CustomToast.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.any,
  hide: PropTypes.bool,
  large: PropTypes.bool,
  children: PropTypes.any,
};
