import React, { Suspense, lazy } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Loading from './views/Loading.js';
const Home = lazy(() => import('./views/Home.js'));
const AboutMe = lazy(() => import('./views/AboutMe.js'));
const Services = lazy(() => import('./views/Services.js'));
const Recipes = lazy(() => import('./views/Recipes.js'));
const Recipe = lazy(() => import('./views/Recipe.js'));
const AskJoana = lazy(() => import('./views/AskJoana.js'));
const Testimonials = lazy(() => import('./views/Testimonials.js'));
const Contact = lazy(() => import('./views/Contact.js'));
const Blog = lazy(() => import('./views/Blog.js'));
const Post = lazy(() => import('./views/Post.js'));
const NoMatch = lazy(() => import('./views/NoMatch.js'));

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-me" component={AboutMe} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/recipes/:categoryID?" component={Recipes} />
          <Route exact path="/recipe/(.*)-:recipeID" component={Recipe} />
          <Route exact path="/ask-joana" component={AskJoana} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/blog" component={Blog} />
          <Route path="/post/(.*)-:postID" component={Post} />
          <Route component={NoMatch} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
